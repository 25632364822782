<template>
  <div class="">
    <Card class="p-m-2">
      <template #title> Kullanıcılar</template>
      <template #content>
        <div class="top-button-area">
          <Button
            icon="pi pi-plus "
            class="p-button-sm p-mr-2 p-mb-2 yeni"
            label="Yeni"
            @click="_KullanıcıModal()"
          />
        </div>
        <DataTable
          v-if="DataTable_render"
          :columns="columns"
          :data="KullanicilarListesi"
          v-on:set-child-data="getDataChild"
        />
      </template>
    </Card>

    <Dialog
      v-model:visible="KullanıcıModal"
      :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <template #header>
        <h4>Yeni Kullanıcı</h4>
      </template>

      <div class="p-grid p-fluid p-formgrid">
        <div class="p-field p-col-6">
          <span for="spanAd">Kullanıcı Adı</span>
          <InputText
            type="text"
            v-model="v$.txtKullaniciAd.$model"
            aria-labelledby="spanKullaniciAdi"
            :class="{ 'p-invalid': v$.txtKullaniciAd.$invalid && submitted }"
          />
          <small
            v-if="
              (v$.txtKullaniciAd.$invalid && submitted) ||
              v$.txtKullaniciAd.$pending.$response
            "
            class="p-error"
            >{{
              v$.txtKullaniciAd.required.$message.replace("Value", "Name")
            }}</small
          >
        </div>
        <div class="p-field p-col-6">
          <span for="spanAd">Ad</span>
          <InputText
            type="text"
            v-model="v$.txtAd.$model"
            aria-labelledby="spanAd"
            :class="{ 'p-invalid': v$.txtAd.$invalid && submitted }"
          />
          <small
            v-if="
              (v$.txtAd.$invalid && submitted) || v$.txtAd.$pending.$response
            "
            class="p-error"
            >{{ v$.txtAd.required.$message.replace("Value", "Name") }}
          </small>
        </div>
        <div class="p-field p-col-6">
          <span for="spanSoyad">Soyad</span>
          <InputText
            type="text"
            v-model="v$.txtSoyad.$model"
            aria-labelledby="spanSoyad"
            :class="{ 'p-invalid': v$.txtSoyad.$invalid && submitted }"
          />
          <small
            v-if="
              (v$.txtSoyad.$invalid && submitted) ||
              v$.txtSoyad.$pending.$response
            "
            class="p-error"
            >{{ v$.txtSoyad.required.$message.replace("Value", "Name") }}
          </small>
        </div>
        <div class="p-field p-col-6">
          <span for="spanSoyad">Rol</span>
          <Dropdown
           filter
            id="drpRol"
            v-model="SelectedRol"
            :options="RolListesi"
            optionLabel="Ad"
            optionValue="Rol_rowid"
            placeholder="Seçiniz..."
            :class="{
              'p-invalid': v$.SelectedRol.$invalid && submitted,
            }"
          />
          <small
            v-if="
              (v$.SelectedRol.$invalid && submitted) ||
              v$.SelectedRol.$pending.$response
            "
            class="p-error"
            >{{
              v$.SelectedRol.required.$message.replace("Value", "Name")
            }}</small
          >
        </div>
        <div class="p-field p-col-6">
          <span for="spanSifre">Sifre</span>
          <Password v-model="txtSifre" aria-labelledby="spanSifre">
            <!-- :class="{ 'p-invalid': v$.txtSifre.$invalid && submitted }" -->
            <template #header>
              <h6>Şifre Seçin</h6>
            </template>
            <template #footer>
              <Divider />
              <p class="mt-2">Kurallar</p>
              <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                <li>Şuan herşey serbest</li>
              </ul>
            </template>
          </Password>
          <!-- <small
              v-if="
                (v$.txtSifre.$invalid && submitted) ||
                v$.txtSifre.$pending.$response
              "
              class="p-error"
              >{{
                v$.txtSifre.required.$message.replace("Value", "Name")
              }}
            </small> -->
        </div>
      </div>
      <template #footer>
        <Button
          label="Tamam"
          icon="pi pi-check"
          class="p-button-sm p-button-success"
          @click="this.handleClick(!v$.$invalid)"
          autofocus
        />
        <Button
          label="Kapat"
          icon="pi pi-times"
          class="p-button-sm p-button-danger"
          @click="this._KullanıcıModal()"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import DataTable from "../DataTable/DataTable.vue";
import GlobalServis from "../../../services/GlobalServis";

import { required, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      DataTable_render: true,
      KullanicilarListesi: [],
      RolListesi: [],
      KullanıcıModal: false,
      KullanıcıPopUpDurumu: { type: Boolean, value: null }, // True = Yeni Kullanici, False = Edit Kullanıcı
      columns: [
        { field: "Ad", header: "Kullanıcı Adi" },
        { field: "OlusturmaTarih", header: "Oluşturma Tarihi" },
      ],
      txtKullaniciAd: null,
      txtAd: null,
      txtSoyad: null,
      SelectedRol: null,
      txtSifre: null,
      Kullanici_rowid: null,
    };
  },
  methods: {
    getDataChild(getdata_child) {
      this.KullanıcıPopUpDurumu = false;
      this.KullanıcıModal = !this.KullanıcıModal;
      this.Kullanici_rowid = getdata_child.data.Kullanici_rowid;
      let data = {
        Kullanici_rowid: getdata_child.data.Kullanici_rowid,
      };
      GlobalServis.GlobalServis("POST", "GetKullaniciInfo", data).then(
        (res) => {
          if (res.status == 200) {
            this.v$.txtKullaniciAd.$model = res.data[0].KullaniciAd;
            this.v$.txtAd.$model = res.data[0].Ad;
            this.v$.txtSoyad.$model = res.data[0].Soyad;
            this.v$.SelectedRol.$model = res.data[0].pRol;
          } else if (res.status == 400) {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Önemli bir hata meydana geldi",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Bilinmeyen bir hata meydana geldi",
              life: 3000,
            });
          }
        }
      );
    },
    handleClick(isFormValid) {
      this.submitted = true;
      if (isFormValid) {
        let data = {
          Kullanici_rowid: this.Kullanici_rowid,
          KullaniciAd: this.txtKullaniciAd,
          Ad: this.txtAd,
          Soyad: this.txtSoyad,
          Rol: this.SelectedRol,
          Sifre: this.txtSifre,
        };
        console.log(data);
        if (this.KullanıcıPopUpDurumu) {
          // Yeni Kullanıcı
          GlobalServis.GlobalServis("POST", "SetKullanici", data).then(
            (res) => {
              if (res.status == 200 && res.data == "") {
                this.$toast.add({
                  severity: "success",
                  summary: "Başarılı",
                  detail: "Kullanıcı oluşturulmuştur",
                  life: 3000,
                });
                this.Refresh();
                this._KullanıcıModal();
              } else if (res.status == 200 && res.data != "") {
                this.$toast.add({
                  severity: "warn",
                  summary: "Uyarı",
                  detail: res.data,
                  life: 3000,
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Hata",
                  detail: "Hata oldu",
                  life: 3000,
                });
              }
            }
          );
        } else {
          // Edit Kullanıcı
          GlobalServis.GlobalServis("POST", "UpdateKullanici", data).then(
            (res) => {
              if (res.status == 200 && res.data == "") {
                this.$toast.add({
                  severity: "success",
                  summary: "Başarılı",
                  detail: "Kullanıcı düzenlemiştir",
                  life: 3000,
                });
                this.Refresh();
                this._KullanıcıModal();
              } else if (res.status == 200 && res.data != "") {
                this.$toast.add({
                  severity: "warn",
                  summary: "Uyarı",
                  detail: res.data,
                  life: 3000,
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Hata",
                  detail: "Hata oldu",
                  life: 3000,
                });
              }
            }
          );
        }
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Gerekli Alan Uyarısı",
          detail: "Lütfen tüm alanları doldurunuz",
          life: 3000,
        });
      }
    },
    _KullanıcıModal() {
      this.KullanıcıPopUpDurumu = true;
      this.KullanıcıModal = !this.KullanıcıModal;
      if (!this.KullanıcıModal) {
        this.resetForm();
      }
    },
    resetForm() {
      // this.v$.$reset;
      (this.txtKullaniciAd = null),
        (this.txtAd = null),
        (this.txtSoyad = null),
        (this.SelectedRol = null),
        (this.txtSifre = null);
    },
    Refresh() {
      this.DataTable_render = false;
      this.$nextTick(() => {
        this.InitServis();
        this.DataTable_render = true;
      });
    },
    InitServis() {
      GlobalServis.GlobalServis("GET", "GetKullaniciTablosu", "").then(
        (res) => {
          if (res.status == 200) {
            this.KullanicilarListesi = res.data;
          } else if (res.status == 400) {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Önemli bir hata meydana geldi",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Bilinmeyen bir hata meydana geldi",
              life: 3000,
            });
          }
        }
      ),
        GlobalServis.GlobalServis("GET", "GetRol", "").then((res) => {
          if (res.status == 200) {
            this.RolListesi = res.data;
          } else if (res.status == 400) {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Önemli bir hata meydana geldi",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Bilinmeyen bir hata meydana geldi",
              life: 3000,
            });
          }
        });
    },
  },
  mounted() {
    this.InitServis();
  },
  components: {
    DataTable,
  },
  validations() {
    return {
      txtKullaniciAd: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      txtAd: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      txtSoyad: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      SelectedRol: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      // txtSifre: {
      //   required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      // },
    };
  },
};
</script>

<style lang="scss" scoped>
.top-button-area .p-button.yeni {
  background: linear-gradient(
    to left,
    var(--purple-600) 50%,
    var(--purple-700) 50%
  );
  background-size: 200% 100%;
  background-position: right bottom;
  transition: background-position 0.5s ease-out;
  color: #fff;
  border-color: var(--purple-700);
}
.top-button-area .p-button.yeni:hover {
  background-position: left bottom;
}
.top-button-area .p-button.yeni i {
  background-color: var(--purple-700);
}
.top-button-area .p-button.yeni:focus {
  box-shadow: 0 0 0 1px var(--purple-400);
}
</style>
